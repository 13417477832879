import { createWebHistory, createRouter } from "vue-router";

import CompanyForm from "../components/CompanyForm.vue";
import CodeForm from "../components/CodeForm.vue";
import ConfigurationForm from "../components/ConfigurationForm.vue";
import ObituaryListForm from "../components/ObituaryListForm.vue"; 
import SalespersonCommissionForm from "../components/SalespersonCommissionForm.vue";

const routes = [    
    {
        path: '/CompanyForm',
        component: CompanyForm
    },
    {
        path: '/CodeForm',
        component: CodeForm
    },
    {
        path: '/Configuration',
        component: ConfigurationForm
    },
    {
        path: '/Obituary',
        component: ObituaryListForm
    },
    {
        path: '/SalespersonCommission',
        component: SalespersonCommissionForm
    }
]; 

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
