import { handleResponse, getGetRequestOptions, getPostRequestOptions } from '../helpers';

export const toolService = {
    Get,
    Post
};

function Get(aUrl) {
    return fetch(`../api/` + aUrl, getGetRequestOptions())
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Post(aUrl, data) {
    return fetch(`../api/` + aUrl, getPostRequestOptions(data))
        .then(handleResponse)
        .then(response => {
            return response;
        });
}