<template>
    <TabView>
        <TabPanel header="Moje konto">
            <div class="grid">
                <!-- Imię -->
                <div class="col-12 md:col-6">
                    <label for="first_name">Imię</label>
                    <InputText id="first_name" v-model="user.firstName" />
                </div>

                <!-- Nazwisko -->
                <div class="col-12 md:col-6">
                    <label for="last_name">Nazwisko</label>
                    <InputText id="last_name" v-model="user.lastName" />
                </div>

                <!-- Telefon -->
                <div class="col-12 md:col-6">
                    <label for="phone">Telefon</label>
                    <InputText id="phone" v-model="user.phone" />
                </div>

                <!-- Email (zablokowane) -->
                <div class="col-12 md:col-6">
                    <label for="email">Email</label>
                    <InputText id="email" v-model="user.email" disabled />
                </div>

                <!-- Data utworzenia (zablokowane) -->
                <div class="col-12 md:col-6">
                    <label for="created_at">Utworzono {{user.createdAt}}</label>
                </div>

                <!-- Data aktualizacji (zablokowane) -->
                <div class="col-12 md:col-6">
                    <label for="updated_at">Zaktualizowano {{user.updatedAt}}</label>
                </div>

                <!-- Status aktywności (zablokowane) -->
                <div class="col-12 md:col-6">
                    <label for="is_active">Aktywny</label>
                    <InputSwitch id="is_active" v-model="user.isActive" disabled />
                </div>

                <!-- Przyciski akcji -->
                <div class="col-12">
                    <Button label="Zapisz" icon="pi pi-check" @click="userSave" />
                    <Button label="Zmień hasło" icon="pi pi-check" @click="userPasswordChange" />
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Dane Firmy">
            <div class="grid">
                <div class="col-12 full-width">
                    <div class="col-4 full-width">
                        <label for="name">Nip</label>
                        <InputGroup>
                            <InputText placeholder="Nip" v-model="company.taxId" />
                            <Button icon="pi pi-search" severity="warning" @click="getFromGUS()" />
                        </InputGroup>
                    </div>
                    <div class="col-4 full-width">
                        <label for="name">Nazwa</label>
                        <InputText id="name" type="text" v-model="company.name" placeholder="Nazwa" />
                    </div>
                    <div class="col-4  full-width">
                        <label for="name">Adres</label>
                        <InputText id="name" type="text" v-model="company.streetAddress" placeholder="Nazwa" />
                    </div>
                    <div class="col-4 full-width">
                        <label for="name">Kod pocztowy</label>
                        <InputText id="name" type="text" v-model="company.postalCode" placeholder="Nazwa" />
                    </div>
                    <div class="col-8 full-width">
                        <label for="name">Miejscowość</label>
                        <InputText id="name" type="text" v-model="company.city" placeholder="Nazwa" />
                    </div>
                    <div class="col-8 full-width">
                        <label for="name">Status Vat</label>
                        <InputText id="name" type="text" v-model="company.statusVat" placeholder="Nazwa" />
                    </div>
                    <div class="col-8 full-width">
                        <label for="name">Numer konta:</label>
                        <Dropdown v-model="company.bankAccount" :options="accountOptions" placeholder="Wybierz numer konta" />
                    </div>
                </div>
            </div>
            <label style="color:red;">{{ errorMsg }}</label>

            <div class="col-12 full-width">
                <Button label="Zapisz" icon="pi pi-check" @click="CompanySaveData()" class="p-button-success" />
            </div>

        </TabPanel>
        <TabPanel header="Użytkownicy">
            <div class="grid">
                <div class="col-12 full-width">
                    <div class="col-6 full-width">
                        <label for="name">Zaproś użytkownika</label>
                        <InputText v-model="inviteUser"
                                   type="email"
                                   placeholder="example@example.com"
                                   @blur="validateEmail"
                                   :class="{'p-invalid': emailError}" />
                        <small v-if="emailError" class="p-error">{{ emailError }}</small>
                    </div>
                    <div class="col-6 full-width">
                        <Button label="Zaproś" icon="pi pi-check" @click="InviteUser()" class="p-button-success" />
                    </div>
                </div>
            </div>
            <div class="col-12 full-width">
                <DataTable v-if="1==0" class="p-datatable-sm" ref="grid" :value="users" showGridlines stripedRows :loading="isBusy">
                    <template #empty>
                        Brak danych
                    </template>
                    <template #loading>
                        <b>Proszę czekać...</b>
                    </template>

                    <Column field="email" header="email" :sortable="true" style="width: 3.5rem">

                    </Column>
                    <Column field="firstName" header="imię i nazwisko" :sortable="true" style="width: 3.5rem">
                        <template #body="slotProps">
                            {{slotProps.data.firstName }} {{slotProps.data.lastName }}
                        </template>
                    </Column>
                    <Column field="phone" header="tel" :sortable="true" style="width: 3.5rem" />
                    <Column field="isActive" header="Status" :sortable="true" style="width: 3.5rem">
                        <template #body="slotProps">
                            {{ (slotProps.data.isActive==true) ? 'Aktywny' : 'Nieaktywny' }}
                        </template>
                    </Column>
                    <Column field="phone" header="Czynności" style="width: 2.5rem">
                        <template #body="slotProps">
                            <Dropdown :options="[{name:'Edytuj'}]"
                                      optionLabel="name"
                                      @change="handleUserDropdown(slotProps.data, $event.value)"
                                      placeholder="Czynności" />
                        </template>
                    </Column>
                </DataTable>
                <TreeTable :value="userNodes">
                    <Column field="email" header="Email" expander></Column>
                    <Column field="firstName" header="imię i nazwisko" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.node.data.firstName }} {{slotProps.node.data.lastName }}
                        </template>
                    </Column>
                    <Column field="phone" header="Tel"></Column>
                    <Column field="isActive" header="Status" :sortable="true">
                        <template #body="slotProps">
                            {{ (slotProps.node.data.isActive==true) ? 'Aktywny' : 'Nieaktywny' }}
                        </template>
                    </Column>
                    <Column field="phone" header="Czynności">
                        <template #body="slotProps">
                            <Dropdown :options="[{name:'Edytuj'}]"
                                      optionLabel="name"
                                      @change="handleUserDropdown(slotProps.node, $event.value)"
                                      placeholder="Czynności" />
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </TabPanel>
        <TabPanel header="Partnerzy">
            <div class="grid">
                <div class="col-12 full-width">
                    <div class="col-6 full-width">
                        <label for="name">Zaproś partnera</label>
                        <InputText v-model="inviteCompany"
                                   type="email"
                                   placeholder="example@example.com"
                                   @blur="validateCompanyEmail"
                                   :class="{'p-invalid': emailError}" />
                        <small v-if="emailCompanyError" class="p-error">{{ emailCompanyError }}</small>
                    </div>
                    <div class="col-6 full-width">
                        <Button label="Zaproś" icon="pi pi-check" @click="InviteCompany()" class="p-button-success" />
                    </div>
                </div>
            </div>
            <div class="col-12 full-width">
                <DataTable class="p-datatable-sm" ref="grid" :value="companies" showGridlines stripedRows :loading="isBusy">
                    <template #empty>
                        Brak danych
                    </template>
                    <template #loading>
                        <b>Proszę czekać...</b>
                    </template>

                    <Column field="name" header="Nazwa" :sortable="true" />
                    <Column field="city" header="Miejscowość" :sortable="true" />
                    <Column field="phone" header="Czynności" style="width: 2.5rem">
                        <template #body="slotProps">
                            <Dropdown :options="[{name:'Edytuj'}]"
                                      optionLabel="name"
                                      @change="handleUserDropdown(slotProps.data, $event.value)"
                                      placeholder="Czynności" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </TabPanel>
    </TabView>
    <Dialog position="top" v-model:visible="changePasswordDisplay" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true" header="Zmień hasło">
        <div class="grid">
            <div class="col-12 full-width">
                <h2>Zmiana hasła</h2>
            </div>

            <div class="col-12 full-width">
                <div class="p-field">
                    <label for="oldPassword">Stare hasło</label>
                    <Password id="oldPassword" v-model="oldPassword" feedback="false" toggleMask />
                    <small v-if="errors.oldPassword" class="p-error">Stare hasło nie może być puste.</small>
                </div>
            </div>

            <div class="col-12 full-width">
                <div class="p-field">
                    <label for="newPassword">Nowe hasło</label>
                    <Password id="newPassword" v-model="newPassword" feedback="false" toggleMask />
                    <small v-if="errors.newPassword" class="p-error">Nowe hasło musi mieć co najmniej 8 znaków.</small>
                </div>
            </div>

            <div class="col-12 full-width">
                <div class="p-field">
                    <label for="confirmPassword">Powtórz nowe hasło</label>
                    <Password id="confirmPassword" v-model="confirmPassword" feedback="false" toggleMask />
                    <small v-if="errors.confirmPassword" class="p-error">Nowe hasło i potwierdzenie hasła muszą być takie same.</small>
                </div>
            </div>
            <small v-if="errors.savePassword != ''" class="p-error">{{ errors.savePassword }}</small>
            <div class="col-12 full-width">
                <Button label="Anuluj"
                        icon="pi pi-times"
                        @click="cancelChange"
                        class="p-button-outlined p-button-secondary" />
                <Button label="Zatwierdź"
                        icon="pi pi-check"
                        @click="passwordChangeSave"
                        class="p-button-outlined p-button-primary" />
            </div>
        </div>
    </Dialog>
</template>
<script>
    import TabView from 'primevue/tabview';
    import TabPanel from 'primevue/tabpanel';
    import { toolService } from '../services/tool.service.js';
    import Password from 'primevue/password';

    export default {
        components: { TabView, TabPanel, Password },
        name: 'CompanyEdit',
        data() {
            return {
                company: {
                    companyId: 0,
                    taxId: '',
                    name: "",
                    streetAddress: "",
                    city: "",
                    postalCode: "",
                    bankAccount: "",
                    accountNumbers: "",
                    statusVat: "",
                },
                user: {
                    userId: 0,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    createdAt: '',
                    updatedAt: '',
                    isActive: false
                },
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                changePasswordDisplay: false,
                errors: {
                    confirmPassword: false,
                    savePassword: false
                },
                inviteUser: '',
                emailError: '',
                users: [],
                inviteCompany: '',
                emailCompanyError: '',
                companies: [],
                userNodes: {}
            };
        },
        computed: {
            getUser() {
                const user = sessionStorage.getItem("User");
                return JSON.parse(user);
            },
            accountOptions() {
                return this.company.accountNumbers.split(';').map(account => (account));
            }
        },
        mounted: function () {
            this.loadUser();
            this.loadCompany();
        },
        methods: {
            buildUserTree(users) {
                const userMap = new Map();

                // Tworzymy mapę użytkowników
                users.forEach(user => {
                    userMap.set(user.userId, {
                        data: user,
                        label: `${user.firstName} ${user.lastName}`,
                        icon: 'pi pi-fw pi-user', // Ikona dla każdego użytkownika
                        children: []
                    });
                });

                // Rekurencyjna funkcja do przypisywania kluczy w formacie "0-0-1"
                function assignKeys(node, parentKey, i) {
                    node.key = parentKey !== null ? `${parentKey}-${i}` : `${node.data.userId}`;
                    node.children.forEach((child, index) => assignKeys(child, node.key, index));
                }

                // Budujemy strukturę drzewa i przypisujemy klucze
                const tree = [];
                users.forEach(user => {
                    if (user.parentId && userMap.has(user.parentId)) {
                        userMap.get(user.parentId).children.push(userMap.get(user.userId));
                    } else {
                        tree.push(userMap.get(user.userId));
                    }
                });

                // Przypisujemy klucze dla każdego z węzłów począwszy od korzeni
                tree.forEach((root, index) => assignKeys(root, null, index));

                return tree;
            },
            handleUserDropdown(item, action) {
                alert("not implemented");
                if (action.name === 'Edytuj') {
                    console.log(item);
                }
                else
                    if (action.name === 'Remove') {
                        this.removeFile(item);
                    }
            },
            cancelChange() {
                // Logika anulowania
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';
                this.changePasswordDisplay = false;
            },
            getFromGUS() {
                let t = this;
                fetch('../api/Configuration/GetCompanyDataFromExternal?nip=' + this.company.taxId, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getUser.token}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        t.company.taxId = data.data.taxId;
                        t.company.name = data.data.name;
                        t.company.streetAddress = data.data.streetAddress;
                        t.company.city = data.data.city;
                        t.company.postalCode = data.data.postalCode;
                        t.company.bankAccount = data.data.bankAccount;
                        t.company.accountNumbers = data.data.accountNumbers;
                        t.company.statusVat = data.data.statusVat;
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });

            },
            CompanySaveData() {
                let item = JSON.parse(JSON.stringify(this.company))

                fetch('../api/Configuration/SetCompany', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getUser.token}`
                    },
                    body: JSON.stringify(item),
                })
                    .then(response => response.json())
                    .then(data => {
                        this.company = data.data;
                        this.togglePanel(data);
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        // this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });
            },
            loadCompany() {
                let t = this;
                fetch('../api/Configuration/GetCompany?companyId=' + this.getUser.companyId, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getUser.token}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        t.company = data.data;
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });
            },
            loadUser() {
                let t = this;
                toolService.Get('Configuration/GetUser?userId=' + this.getUser.id).then(data => {
                    console.log(data);
                    t.user = data.data;
                })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });

                toolService.Get('Configuration/GetUsers').then(data => {
                    console.log(data);
                    t.users = data.data;
                    t.userNodes = t.buildUserTree(data.data);
                })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });

                toolService.Get('Configuration/GetCompanies').then(data => {
                    console.log(data);
                    t.companies = data.data;
                })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });


            },
            userSave() {
                let item = JSON.parse(JSON.stringify(this.user))
                toolService.Post('Configuration/SetUser', item).then(data => {
                    console.log(data);

                })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });

            },
            userPasswordChange() {
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';
                this.errors.oldPassword = false;
                this.errors.newPassword = false;
                this.errors.confirmPassword = false;
                this.changePasswordDisplay = true;
            },
            passwordChangeSave() {
                this.errors.oldPassword = false;
                this.errors.newPassword = false;
                this.errors.confirmPassword = false;

                if (!this.oldPassword) {
                    this.errors.oldPassword = true;
                }

                // Walidacja nowego hasła
                if (this.newPassword.length < 8) {
                    this.errors.newPassword = true;
                }

                // Sprawdzenie zgodności nowego hasła z powtórzeniem
                if (this.newPassword !== this.confirmPassword) {
                    this.errors.confirmPassword = true;
                }


                if (!this.errors.oldPassword && !this.errors.newPassword && !this.errors.confirmPassword) {
                    let item = {
                        userId: this.getUser.id,
                        oldPassword: this.oldPassword,
                        newPassword: this.newPassword
                    };
                    toolService.Post('Configuration/SetUserPassword', item).then(data => {
                        console.log(data);
                        if (data.status == 0) {
                            this.changePasswordDisplay = false;
                        }
                        else {
                            this.errors.savePassword = data.message;
                        }
                    })
                        .catch(error => {
                            console.error('Błąd:', error);
                            //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                        });
                }
            },
            InviteUser() {
                this.validateEmail()
                if (this.emailError != '') {
                    return
                }
                let item = { email: this.inviteUser };
                toolService.Post('Configuration/InviteUser', item).then(data => {
                    console.log(data);
                    if (data.status == 0) {
                        this.this.inviteUser = '';
                    }
                    else {
                        this.emailError = data.message;
                    }
                })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });
            },
            validateEmail() {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.inviteUser) {
                    this.emailError = 'Adres e-mail nie może być pusty.';
                } else if (!emailPattern.test(this.inviteUser)) {
                    this.emailError = 'Podany adres e-mail jest niepoprawny.';
                } else {
                    this.emailError = '';
                }
            },
            validateCompanyEmail() {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.inviteCompany) {
                    this.emailCompanyError = 'Adres e-mail nie może być pusty.';
                } else if (!emailPattern.test(this.inviteCompany)) {
                    this.emailCompanyError = 'Podany adres e-mail jest niepoprawny.';
                } else {
                    this.emailCompanyError = '';
                }
            },
            InviteCompany() {
                this.validateCompanyEmail()
                if (this.emailCompanyError != '') {
                    return
                }
                let item = { email: this.inviteCompany };
                toolService.Post('Configuration/InviteCompany', item).then(data => {
                    console.log(data);
                    if (data.status == 0) {
                        this.this.inviteCompany = '';
                    }
                    else {
                        this.emailError = data.message;
                    }
                })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });
            }
        }
    };
</script>

<style scoped>

    .full-width > * {
        width: 100%;
    }
</style>