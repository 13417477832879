<template>
    <TabView>
        <TabPanel header="Sprzedaż">
            <div class="col-12 full-width">
                <Button label="Odśwież" icon="pi pi-info-circle" @click="GetSaleReport" />
                <DataTable class="p-datatable-sm" :value="saleReport" showGridlines stripedRows :loading="isBusy">
                    <template #empty>
                        Brak danych
                    </template>
                    <template #loading>
                        <b>Proszę czekać...</b>
                    </template>


                    <!-- Kolumna dla imienia i nazwiska -->
                    <Column field="firstName" header="Imię" :sortable="true" />
                    <Column field="lastName" header="Nazwisko" :sortable="true" />

                    <Column field="name" header="Imię" :sortable="true" />
                    <Column field="surname" header="Nazwisko" :sortable="true" />

                    <!-- Kolumna dla statusu transakcji -->
                    <Column field="transactionStatus" header="Status Transakcji" :sortable="true">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.transactionStatus === 1">Zakończona</span>
                            <span v-else-if="slotProps.data.transactionStatus === 0">W toku</span>
                            <span v-else></span>
                        </template>
                    </Column>

                    <!-- Kolumna dla wartości transakcji -->
                    <Column field="transactionValue" header="Wartość Transakcji" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.transactionValue !== undefined ? `${slotProps.data.transactionValue.toFixed(2)} zł` : '' }}
                        </template>
                    </Column>

                    <!-- Kolumna dla daty transakcji -->
                    <Column field="transactionDate" header="Data Transakcji" :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.transactionDate ? new Date(slotProps.data.transactionDate).toLocaleString() : '' }}
                        </template>
                    </Column>

                    <!-- Kolumna dla czynności -->
                    <Column v-if="1==0" header="Czynności" style="width: 2.5rem">
                        <template #body="slotProps">
                            <Dropdown :options="[{name:'Edytuj'}]"
                                      optionLabel="name"
                                      @change="handleUserDropdown(slotProps.data, $event.value)"
                                      placeholder="Czynności" />
                        </template>
                    </Column>
                </DataTable>

            </div>
        </TabPanel>
        <TabPanel header="Rozliczenia">
            <Button label="Odśwież" icon="pi pi-info-circle" @click="GetCommission" />
            <TreeTable :value="userNodes" :loading="isBusy">
                <template #empty>
                    Brak danych
                </template>
                <template #loading>
                    <b>Proszę czekać...</b>
                </template>
                <Column field="userId" header="Użytkownik" expander>
                    <template #body="slotProps">
                        {{slotProps.node.firstName }} {{slotProps.node.lastName }}
                    </template>
                </Column>
                <Column field="userId" header="Prowizja" expander>
                    <template #body="slotProps">
                        {{ slotProps.node.commissionValue }}
                    </template>
                </Column>

                <Column field="phone" header="Czynności">
                    <template #body="slotProps">
                        <Dropdown :options="[{name:'Pokaż szczegóły'}]"
                                  optionLabel="name"
                                  @change="handleUserDropdown(slotProps.node, $event.value)"
                                  placeholder="Czynności" />
                    </template>
                </Column>
            </TreeTable>
        </TabPanel>
    </TabView>
</template>

<script>
    import TabView from 'primevue/tabview';
    import TabPanel from 'primevue/tabpanel';
    import { toolService } from '../services/tool.service.js';


    export default {
        props: {
        },
        components: { TabView, TabPanel },
        data() {
            return {
                isBusy: false,
                userNodes: [],
                saleReport:[]
            };
        },
        methods: {
            handleUserDropdown(item, action){
                alert("not implemented");
                console.log(item);
                console.log(action);
            },
            GetSaleReport() {
                let t = this;
                t.isBusy = true;
                toolService.Get('SalespersonCommission/GetSaleReport')
                    .then(data => {
                        //localStorage.setItem("GetCommission", JSON.stringify(data));
                        console.log(data);
                        t.saleReport = data.data;
                        t.isBusy = false;
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        t.isBusy = false;
                    });
            },
            GetCommission() {
                let t = this;
                t.isBusy = true;
                toolService.Get('SalespersonCommission/GetCommission')
                    .then(data => {
                        localStorage.setItem("GetCommission", JSON.stringify(data));
                        console.log(data);
                        t.userNodes = data.data;
                        t.isBusy = false;
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        t.isBusy = false;
                    });

            }
        }
    };
</script>

<style scoped>
   
</style>