<template>
    <div class="barcode-reader" style="text-align:center; margin-top: 50px;">
        <h1>Odczyt kodu kreskowego</h1>
        <label for="barcodeInput">Zeskanuj kod kreskowy:</label><br><br>
        <input v-model="barcode"
               id="barcodeInput"
               placeholder="Odczytaj kod kreskowy"
               @keydown.enter="handleSubmit"
               autofocus
               type="text" />
        <Dropdown v-model="period" :options="[{name:5},{name:10},{name:20}]" optionLabel="name" placeholder="Okres" />

        <Message v-for="msg of messages" :key="msg.id" severity="error" :life="3000">{{ msg.content }}</Message>
    </div>
    <div>        
        <h2>Zapisane kody kreskowe:</h2>
        <ul>
            <li v-for="code in barcodeList" :key="code.codeId">
                {{ code.barCode }} - {{ code.period }} lat
                <Dropdown :options="[{name:'Edit', label:'Edytuj'},{name:'Remove', label:'Usuń'}]"
                          optionLabel="label"
                          @change="handleDropdownChange(code, $event)"
                          placeholder="Akcja" />

            </li>
        </ul>
    </div>
    <ConfirmDialog></ConfirmDialog>
    <OverlayPanel ref="overlay" :dismissable="true">
        <h4>Edycja kodu</h4>
        <Dropdown v-model="itemPeriod" :options="[{name:5},{name:10},{name:20}]" optionLabel="name" placeholder="Okres" />
        <Button label="Zapisz" icon="pi pi-info-circle" @click="editItemSave" />
    </OverlayPanel>
</template>

<script>
    import { ref } from 'vue';
    export default {
        setup() {
            const overlay = ref(null);
            const togglePanel = (event) => {
                overlay.value.toggle(event);
            };
            return {
                overlay,
                togglePanel
            };
        },
        name: 'CompanyEdit',        
        data() {
            return {
                barcode: '',
                period: null,
                itemPeriod: null,
                editItem: null,
                barcodeList: [],
                messages: []                
            };
        },
        mounted: function () {
            this.getList();            
        },
        computed: {
            getUser() {
                const user = sessionStorage.getItem("User");
                return JSON.parse(user);
            }
        },
        methods: {           
            editItemSave() {
                let item = JSON.parse(JSON.stringify(this.editItem))
                item['oper'] = 'update';
                item['period'] = this.itemPeriod.name;
                fetch('../api/Code/EditCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getUser.token}`
                    },
                    body: JSON.stringify(item),
                })
                    .then(response => response.json())
                    .then(data => {
                        this.editItem.period = this.itemPeriod.name;
                        this.togglePanel(data);
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });
            },
            deleteItem(obj) {                
                obj['oper'] = 'delete';
                fetch('../api/Code/EditCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getUser.token}`
                    },
                    body: JSON.stringify(obj),
                })
                    .then(response => response.json())
                    .then(data => {
                        this.barcodeList = this.barcodeList.filter(item => item.codeId !== data.codeId);
                        
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });


                console.log(obj);
                console.log('Element został usunięty.');                
            },
            handleDropdownChange(obj, action) {                
                if (action.value.name == "Remove")
                {
                    this.$filters.confirmAction(
                        'Czy na pewno chcesz usunąć ten element?',
                        'Potwierdzenie usunięcia',
                        () => {
                            // Akcja po zaakceptowaniu
                            this.deleteItem(obj);
                        },
                        () => {
                            // Akcja po odrzuceniu
                            console.log('Usunięcie odrzucone');
                        }
                    );                 
                }
                if (action.value.name == "Edit") {
                    this.editItem = obj;
                    this.itemPeriod = { name: obj.period };
                    this.togglePanel(action.originalEvent);
                    
                }
            },
            getList() {
                let thisv = this;
                fetch('../api/Code/GetCode', {
                    method: 'GET',
                    headers: {                        
                        'Authorization': `Bearer ${this.getUser.token}` // Dodanie Bearer Token do nagłówka
                    },                    
                })
                    .then(response => response.json())
                    .then(data => {                        
                        thisv.barcodeList = data;
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                    });
            },
            handleSubmit() {
                let thisv = this;
                console.log("Wysłany kod kreskowy:", this.barcode);
                if (thisv.period == null) {
                    this.messages.push({ id: 2, content: 'Okres nie został wybrany' });
                    return;
                }
                
                // Wysyłanie danych do serwera (możesz dostosować endpoint)
                fetch('../api/Code/AddCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getUser.token}` // Dodanie Bearer Token do nagłówka
                    },
                    body: JSON.stringify({ barcode: thisv.barcode, period: thisv.period.name }),
                })
                    .then(response => response.json())
                    .then(data => {                        
                        thisv.barcode = '';
                        thisv.barcodeList.unshift(data);    
                        thisv.messages = [];
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        this.messages.push({ id: 2, content: 'Błąd zapisywania'});
                    });
            }           
        }
    };
</script>

<style scoped>
    .company-edit {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .p-field {
        margin-bottom: 16px;
    }

    .p-field-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
</style>