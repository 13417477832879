<template>
    <div class="company-edit">
        <h2>Dane firmy</h2>
        <form @submit.prevent="submitForm">
            <div class="p-field p-col-12 p-md-12 p-px-2 p-py-0">
                <div class="p-inputgroup flex-1">
                    <label for="tax_id">Numer podatkowy(NIP)</label>
                    <InputText id="tax_id" type="text" v-model="form.tax_id" placeholder="Wprowadź numer podatkowy" />
                    <Button icon="pi pi-search" severity="success" @click="GetFromGUS()" />
                </div>
            </div>
            
            <div class="p-field">
                <label for="name">Nazwa firmy</label>
                <InputText id="name" v-model="form.name" placeholder="Wprowadź nazwę firmy" />
            </div>

            <div class="p-field">
                <label for="street_address">Adres</label>
                <InputText id="street_address" v-model="form.street_address" placeholder="Wprowadź adres" />
            </div>

            <div class="p-field">
                <label for="postal_code">Kod pocztowy</label>
                <InputText id="postal_code" v-model="form.postal_code" placeholder="Wprowadź kod pocztowy" />
            </div>

            <div class="p-field">
                <label for="city">Miasto</label>
                <InputText id="city" v-model="form.city" placeholder="Wprowadź miasto" />
            </div>

           

            <div class="p-field-checkbox">
                <Checkbox inputId="is_vat" v-model="form.is_vat" :binary="true" />
                <label for="is_vat">Płatnik VAT</label>
            </div>
            


            <Button type="submit" label="Zapisz" class="p-button-success" />
        </form>
    </div>
</template>

<script>
    export default {
        name: 'CompanyEdit',
        props: {
            company: {
                type: Object,
                required: true,
                default: () => ({
                    name: '',                    
                    street_address: '',
                    postal_code: '',
                    city: '',                    
                    tax_id: '',
                    is_vat: false
                })
            }
        },
        data() {
            return {
                form: {
                    name: this.company.name,                    
                    street_address: this.company.street_address,
                    postal_code: this.company.postal_code,
                    city: this.company.city,
                    tax_id: this.company.tax_id,
                    is_vat: this.company.is_vat
                }
            };
        },
        methods: {
            submitForm() {
                // Emisja zaktualizowanych danych do komponentu nadrzędnego
                this.$emit('update-company', this.form);
            }
        }
    };
</script>

<style scoped>
    .company-edit {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .p-field {
        margin-bottom: 16px;
    }

    .p-field-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
</style>