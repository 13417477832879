<template>
    <div class="set-password-container">
        <h2>Set Your Password</h2>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="password">New Password:</label>
                <input v-model="password" type="password" id="password" required />
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirm Password:</label>
                <input v-model="confirmPassword" type="password" id="confirmPassword" required />
            </div>
            <button type="submit">Set Password</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                password: '',
                confirmPassword: '',
                token: ''
            };
        },
        mounted() {
            // Pobierz token z URL
            //https://example.com/activate?token=abcd1234
            const urlParams = new URLSearchParams(window.location.search);
            this.token = urlParams.get('token');

            if (!this.token) {
                alert('Token is missing!');
                // Przekieruj użytkownika w inne miejsce, jeśli token jest nieprawidłowy
            }
        },
        methods: {
            submitForm() {
                // Prosta walidacja czy hasła są takie same
                if (this.password !== this.confirmPassword) {
                    alert("Passwords do not match!");
                    return;
                }

                // Wysyłka hasła i tokenu do API, aby dokończyć proces rejestracji
                this.completeRegistration();
            },
            completeRegistration() {
                // Zastąp URL prawdziwym endpointem API
                fetch('../api/Register/CompleteRegistration', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: this.token,
                        password: this.password,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.status == 0) {
                            // Przekierowanie np. na stronę logowania
                            this.$router.push('/');
                        } else {
                            alert("There was an error: " + data.message);
                        }
                    })
                    .catch(error => {
                        console.error("Error:", error);
                        alert("There was an error during registration.");
                    });
            }
        }
    };
</script>

<style scoped>
    .set-password-container {
        max-width: 400px;
        margin: auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
    }

    button {
        padding: 10px 15px;
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

        button:hover {
            background-color: #218838;
        }
</style>