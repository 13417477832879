import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';

import Menu from "primevue/menu";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Column from 'primevue/column';
import Sidebar from "primevue/sidebar";
import Calendar from "primevue/calendar";
import Textarea from 'primevue/textarea';
import Checkbox from "primevue/checkbox";
import Dropdown from 'primevue/dropdown';
import ScrollTop from 'primevue/scrolltop';
import DataTable from 'primevue/datatable';
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import RadioButton from "primevue/radiobutton";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import ToastService from 'primevue/toastservice';
import ProgressSpinner from "primevue/progressspinner";
import ConfirmationService from 'primevue/confirmationservice';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import OverlayPanel from 'primevue/overlaypanel';
import Panel from 'primevue/panel';
import TreeTable from 'primevue/treetable';
import Toolbar from 'primevue/toolbar';
import InputMask from 'primevue/inputmask';



import 'primevue/resources/themes/aura-dark-green/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'

const primeVueLocale = {};
primeVueLocale['PL'] = {
    startsWith: 'Zaczyna się',
    contains: 'Zawiera',
    notContains: 'Nie zawiera',
    endsWith: 'Kończy się',
    equals: 'Równe',
    notEquals: 'Nie równe',
    noFilter: 'Brak filtra',
    lt: 'Mniejsze niż',
    lte: 'Mniejsze lub równe',
    gt: 'Większe niż',
    gte: 'Większe lub równe',
    dateIs: 'Data jest',
    dateIsNot: 'Data nie jest',
    dateBefore: 'Data przed',
    dateAfter: 'Data po',
    clear: 'Wyczyść',
    apply: 'Zastosuj',
    matchAll: 'Logiczne "i"',
    matchAny: 'Logiczne "lub"',
    addRule: 'Dodaj regułę',
    removeRule: 'Usuń regułę',
    accept: 'Tak',
    reject: 'Nie',
    choose: 'Wybierz',
    upload: 'Wyślij',
    cancel: 'Anuluj',
    dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    dayNamesShort: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pt", "Sob"],
    dayNamesMin: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
    monthNames: [
        "Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik",
        "Listopad", "Grudzień"
    ],
    monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
    today: 'Dzisiaj',
    weekHeader: 'Tydz',
    firstDayOfWeek: 1,
    dateFormat: 'dd-mm-yy',
    weak: 'Cienki',
    medium: 'Średni',
    strong: 'Gruby',
    passwordPrompt: 'Podaj hasło',
    emptyFilterMessage: 'Brak wyników',
    emptyMessage: 'Brak opcji'
};


const app = createApp(App);

app.use(router);
app.use(PrimeVue, { locale: primeVueLocale['PL'] });
app.use(ToastService);
app.use(ConfirmationService);

app.component('Menu', Menu);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('Message', Message);
app.component('Column', Column);
app.component('Sidebar', Sidebar);
app.component('Calendar', Calendar);
app.component('Textarea', Textarea);
app.component('Checkbox', Checkbox);
app.component('Dropdown', Dropdown);
app.component('ScrollTop', ScrollTop);
app.component('DataTable', DataTable);
app.component('InputText', InputText);
app.component('FloatLabel', FloatLabel);
app.component('InputNumber', InputNumber);
app.component('RadioButton', RadioButton);
app.component('InputSwitch', InputSwitch);
app.component('ProgressSpinner', ProgressSpinner);
app.component('ConfirmDialog', ConfirmDialog);
app.component('OverlayPanel', OverlayPanel);
app.component('Panel', Panel); 
app.component('TreeTable', TreeTable); 
app.component('Toolbar', Toolbar);
app.component('InputMask', InputMask);


app.config.globalProperties.$filters = {
    confirmAction(message, header, acceptCallback, rejectCallback) {
        app.config.globalProperties.$confirm.require({
            message: message,
            header: header,
            icon: 'pi pi-exclamation-triangle',
            accept: acceptCallback,
            reject: rejectCallback,
        });
    }
};

app.mount('#app');
