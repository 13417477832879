<template>
    <Panel header="">
        <div class="grid">
            <div class="col-3">
                <div class="full-width">
                    <Button type="button" label="Dodaj" icon="pi pi-plus" @click="createNewCustomer" class="p-button-success" style="margin-top:17px;" />
                </div>
            </div>
            <div class="col-3">
                <div class="full-width">
                    <Button type="button" label="Wyczyść" icon="pi pi-times-circle" @click="clear()" class="p-button-success" style="margin-top:17px;" />
                </div>
            </div>
        </div>
    </Panel>

    <Stepper v-if="1==0" orientation="vertical">
        <StepperPanel header="Header I">
            <template #content="{ nextCallback }">
                <div class="flex flex-column h-12rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content I</div>
                </div>
                <div class="flex py-4">
                    <Button label="Next" @click="nextCallback" />
                </div>
            </template>
        </StepperPanel>
        <StepperPanel header="Header II">
            <template #content="{ prevCallback, nextCallback }">
                <div class="flex flex-column h-12rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content II</div>
                </div>
                <div class="flex py-4 gap-2">
                    <Button label="Back" severity="secondary" @click="prevCallback" />
                    <Button label="Next" @click="nextCallback" />
                </div>
            </template>
        </StepperPanel>
        <StepperPanel header="Header III">
            <template #content="{ prevCallback }">
                <div class="flex flex-column h-12rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content III</div>
                </div>
                <div class="flex py-4">
                    <Button label="Back" severity="secondary" @click="prevCallback" />
                </div>
            </template>
        </StepperPanel>
    </Stepper>

    <Dialog position="top" v-model:visible="newCustomerDisplay" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true">
        <h3 class="p-mt-4">Dane klienta</h3>
        <div class="grid">
            <div class="col-6">
                <div class="full-width">
                    <label for="Imie">Imię:</label>
                    <InputText id="Imie" type="text" v-model="customer.name" placeholder="Imie" />
                </div>
            </div>
            <div class="col-6">
                <div class="full-width">
                    <label for="Nazwisko">Nazwisko:</label>
                    <InputText id="Nazwisko" type="text" v-model="customer.surname" placeholder="Nazwisko" />
                </div>
            </div>
            <div class="col-4">
                <div class="full-width">
                    <label for="clientForename">Numer telefonu</label>
                    <InputMask id="Tel" type="text" v-model="customer.phone" placeholder="Telefon" mask="999 999 999" />
                </div>
            </div>
            <div class="col-8">
                <div class="full-width">
                    <label for="Email">Email</label>
                    <InputText id="Email" type="text" v-model="customer.email" placeholder="Email" />
                </div>
            </div>
            <div class="col-12">
                <div>
                    <Checkbox v-model="customer.termsAccepted" inputId="termsAccepted" :binary="true" style="margin-top: 10px; margin-right: 10px;" />
                    <label for="termsAccepted">Akceptuję regulamin</label>
                </div>
                <div>
                    <Checkbox v-model="customer.marketingConsent" inputId="marketingConsent" :binary="true" style="margin-top: 10px; margin-right: 10px;" />
                    <label style="margin-bottom: 10px;" for="marketingConsent">Wyrażam zgodę na przesyłanie treści marketingowych</label>
                </div>
                <div>
                    <Checkbox v-model="customer.eReceiptConsent" inputId="eReceiptConsent" :binary="true" style="margin-top: 10px; margin-right: 10px;" />
                    <label for="eReceiptConsent">Wyrażam zgodę na otrzymywanie paragonów fiskalnych w formie elektronicznej na adres e-mail podany podczas składania zamówienia</label>
                </div>
            </div>
            <div class="col-12 full-width">
                <Button label="Anuluj"
                        icon="pi pi-times"
                        @click="newCustomerDisplay = false"
                        class="p-button-outlined p-button-secondary" />
                <Button label="Zatwierdź"
                        icon="pi pi-check"
                        @click="CustomerSave"
                        class="p-button-outlined p-button-primary" />
            </div>
        </div>

        <ObituaryForm v-if="1==0"> </ObituaryForm>
    </Dialog>

    <Panel v-if="getCustomer.email != undefined" header="Klient">
        <p class="m-0">
            Imię i nazwisko:{{getCustomer.name}} {{getCustomer.surname}}
            <br>
            email: {{getCustomer.email}}
            <br>
            tel.: {{getCustomer.phone}}
        </p>
        <div class="col-12 full-width">
            <Button label="Dodaj eNekrolog"
                    icon="pi pi-plus"
                    @click="NewEObituary"
                    class="p-button-outlined p-button-primary" />
        </div>
    </Panel>
    <Panel v-if="getObituary.epadoCode != undefined" header="eNekrolog">
        <div class="grid">
            <div class="col-6">
                <div class="full-width">
                    <p>{{getObituary.description}}</p>
                </div>
            </div>            
            <div class="col-4">
                <div class="full-width">
                    <a :href="'https://epado.com/' + getObituary.epadoCode" target="_blank">
                        {{ 'https://epado.com/' + getObituary.epadoCode }}
                    </a>
                </div>
                <div v-if="getPaymentUrl && getPaymentUrl.transactionStatusName === 'COMPLETED'" class="col-4">
                    <h3>Opłacone</h3>
                </div>
                <div v-if="getPaymentUrl === undefined" class="full-width" style="margin:20px;">
                    <Button label="Generuj link do płatności" icon="pi pi-pluse" @click="paymentCreateDisplay = true" class="p-button-secondary" />
                </div>
                <div v-if="getPaymentUrl && getPaymentUrl.transactionStatusName !== 'COMPLETED'" class="full-width" style="margin:20px;">
                    <a :href="getPaymentUrl.redirectUri" target="_blank">
                        Opłać
                    </a>
                </div>
                <div v-if="getPaymentUrl && getPaymentUrl.transactionStatusName !== 'COMPLETED'" class="full-width" style="margin:20px;">
                    <Button label="Sprawdź" icon="pi pi-pluse" @click="paymentCheck" class="p-button-secondary" />
                </div>
            </div>
            <div class="col-2">
                <div class="full-width">
                    <img v-if="qrCodeBase64"
                         :src="qrCodeBase64"
                         alt="QR Code"
                         style="border: 1px solid #ccc; padding: 10px; width: 40%;" />
                </div>
            </div>
        </div>

        <div class="col-12 full-width">
            <Button label="Dodaj Nekrolog"
                    icon="pi pi-plus"
                    @click="wordShow=true;"
                    class="p-button-outlined p-button-primary" />
        </div>
    </Panel>
    <Dialog position="top" v-model:visible="eObituaryDisplay" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true">
        <h3 class="p-mt-4">Dane zmarłego</h3>
        <div class="grid">
            <div class="col-5">
                <div class="full-width">
                    <label for="Imie">Imię:</label>
                    <InputText id="Imie" type="text" v-model="eObituary.firstName" placeholder="Imie" />
                </div>
            </div>
            <div class="col-5">
                <div class="full-width">
                    <label for="Nazwisko">Nazwisko:</label>
                    <InputText id="Nazwisko" type="text" v-model="eObituary.lastName" placeholder="Nazwisko" />
                </div>
            </div>
            <div class="col-2">
                <div class="full-width">
                    <label for="Email">Płeć</label>
                    <Dropdown v-model="eObituary.sex" :options="sexList" optionLabel="label" optionValue="Id" placeholder="Wybierz płeć" />
                </div>
            </div>
            <div class="col-3">
                <div class="full-width">
                    <label for="clientForename">Data ur.</label>
                    <Calendar id="dataod-input" v-model="eObituary.birthDate" :showButtonBar="true" :showIcon="true" dateFormat="dd-mm-yy" selectionMode="single" />
                </div>
            </div>
            <div class="col-3">
                <div class="full-width">
                    <label for="clientForename">Data śmierci</label>
                    <Calendar id="dataod-input" v-model="eObituary.deathDate" :showButtonBar="true" :showIcon="true" dateFormat="dd-mm-yy" selectionMode="single" />
                </div>
            </div>
            <div class="col-3">
                <div class="full-width">
                    <label for="clientForename">Data pogrzebu</label>
                    <Calendar id="dataod-input" v-model="eObituary.funeralDateInternal" :showButtonBar="true" :showIcon="true" dateFormat="dd-mm-yy" selectionMode="single" />
                </div>
            </div>
            <div class="col-3">
                <div class="full-width">
                    <label for="clientForename">Godzina pogrzebu</label>
                    <Calendar id="calendar-timeonly" v-model="eObituary.funeralTime" timeOnly :showButtonBar="false" :showIcon="true" hourFormat="24" />
                </div>
            </div>
            <div class="col-6">
                <div class="full-width">
                    <label for="locationDropdown">Miejsce ceremoni pogrzebowej</label>
                    <Dropdown v-model="eObituary.funeralCeremonyLocationId" :options="getFuneralLocation" optionLabel="label" optionValue="locationId" placeholder="Wybierz..." appendTo="body" :showClear="true" :filter="true">
                        <template #footer>
                            <Button label="Dodaj nową lokalizację"
                                    icon="pi pi-plus"
                                    class="p-button-text"
                                    @click="showAddLocationDialog = true" />
                        </template>
                    </Dropdown>
                </div>
            </div>
            <div class="col-6">
                <div class="full-width">
                    <label for="Email">Cmentarz</label>
                    <Dropdown v-if="1==0" v-model="eObituary.cemeteryId" :options="getCemetery" optionLabel="label" optionValue="cemeteryId" placeholder="Wybierz cmentarz" :filter="true" />
                    <Dropdown v-model="eObituary.cemeteryId"
                              :options="filteredOptions"
                              optionLabel="label"
                              optionValue="cemeteryId"
                              editable
                              :filter="false"
                              @filter="onFilter"
                              placeholder="Wybierz cmentarz" />
                </div>
            </div>
            <div class="col-2">
                <div class="full-width">
                    <label for="Email">Aleja</label>
                    <InputText id="Email" type="text" v-model="eObituary.alley" placeholder="Aleja" />
                </div>
            </div>
            <div class="col-2">
                <div class="full-width">
                    <label for="Email">Kwatera</label>
                    <InputText id="Email" type="text" v-model="eObituary.plot" placeholder="Kwatera" />
                </div>
            </div>
            <div class="col-2">
                <div class="full-width">
                    <label for="Email">eNekrolog opis</label>
                    <Textarea v-model="eObituary.description" style="width: 100%" />
                </div>
            </div>

            <div class="col-12 full-width">
                <Button label="Anuluj"
                        icon="pi pi-times"
                        @click="eObituaryDisplay = false"
                        class="p-button-outlined p-button-secondary" />
                <Button label="Zatwierdź"
                        icon="pi pi-check"
                        @click="eObituarySave()"
                        class="p-button-outlined p-button-primary" />
            </div>
        </div>

        <ObituaryForm v-if="1==0"> </ObituaryForm>
    </Dialog>
    <Dialog position="top" v-model:visible="showAddLocationDialog" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true">
        <h3 class="p-mt-4">Lokalizacja ceremonii pogrzebowej</h3>
        <div class="grid">
            <div class="col-12">
                <div class="full-width">
                    <label for="name">Nazwa lokalizacji:</label>
                    <InputText id="name" type="text" v-model="funeralLocation.name" placeholder="Podaj nazwę lokalizacji" />
                </div>
            </div>
            <div class="col-6">
                <div class="full-width">
                    <label for="address">Adres:</label>
                    <InputText id="address" type="text" v-model="funeralLocation.address" placeholder="Podaj adres" />
                </div>
            </div>
            <div class="col-1">
                <div class="full-width">
                    <label for="postalCode">Kod pocztowy:</label>
                    <InputText id="postalCode" type="text" v-model="funeralLocation.postalCode" placeholder="Podaj kod pocztowy" />
                </div>
            </div>
            <div class="col-5">
                <div class="full-width">
                    <label for="city">Miasto:</label>
                    <InputText id="city" type="text" v-model="funeralLocation.city" placeholder="Podaj miasto" />
                </div>
            </div>

            <div class="col-12 full-width">
                <Button label="Anuluj"
                        icon="pi pi-times"
                        @click="showAddLocationDialog = false"
                        class="p-button-outlined p-button-secondary" />
                <Button label="Zatwierdź"
                        icon="pi pi-check"
                        @click="LocationSave()"
                        class="p-button-outlined p-button-primary" />
            </div>
        </div>

        <ObituaryForm v-if="1==0"> </ObituaryForm>
    </Dialog>
    <Dialog position="top" v-model:visible="wordShow" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true">
        <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=http://house.funny7.pl/ytvvraimfzikqnjacxrquimpjwpkidsnjnkojfbphgrjnmilpocwlrkmqrqoasba/klepsydra-wzor-1.docx" width="800" height="600"></iframe>
        <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=http://house.funny7.pl/ytvvraimfzikqnjacxrquimpjwpkidsnjnkojfbphgrjnmilpocwlrkmqrqoasba/klepsydra-wzor-2-s.docx" width="800" height="600"></iframe>

    </Dialog>
    <Dialog position="top" v-model:visible="paymentCreateDisplay" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true">

        <h3 class="p-mt-4">Płatność</h3>
        <div class="grid">
            <div class="col-2">
                <div class="full-width">
                    <label for="Email">Waluta</label>
                    <Dropdown v-model="payment.currency" :options="getCurrency" placeholder="Wybierz walutę" @change="onCurrencyChange" />
                </div>
            </div>
            <div class="col-10">
                <div class="full-width">
                    <label for="Email">Product</label>
                    <Dropdown v-model="payment.product" :options="getProducts.filter(item =>item.currency == payment.currency).map(item => ({ productId: item.productId, nameWithPrice: `${item.name} - ${item.price} [${item.currency}]`}))" optionValue="productId" optionLabel="nameWithPrice" placeholder="Wybierz Product" />


                </div>
            </div>
            <div class="col-12 full-width">
                <Button label="Anuluj"
                        icon="pi pi-times"
                        @click="paymentCreateDisplay = false"
                        class="p-button-outlined p-button-secondary" />
                <Button label="Zatwierdź"
                        icon="pi pi-check"
                        @click="PaymentSave()"
                        class="p-button-outlined p-button-primary" />
            </div>
        </div>
    </Dialog>
    <ConfirmDialog></ConfirmDialog>

</template>

<script>
    import ObituaryForm from './ObituaryForm.vue'
    import { toolService } from '../services/tool.service.js';
    import Stepper from 'primevue/stepper';
    import StepperPanel from 'primevue/stepperpanel';

    export default {        
        name: 'CompanyEdit',   
        components: { ObituaryForm, Stepper, StepperPanel },
        data() {
            return {
                newCustomerDisplay: false,
                refresh : 0,
                customer: {
                    name: '',
                    surname: '',
                    phone: '',
                    email: '',
                    termsAccepted: true,
                    marketingConsent: true,
                    eReceiptConsent: true
                },
                eObituaryDisplay: false,
                eObituary: {
                    firstName: '',
                    lastName: '',
                    funeralDate: null,
                    funeralTime: null,
                    birthDate: null,
                    deathDate: null,
                    cemeteryId: null,
                    alley: null,
                    plot: null,
                    sex: '',
                    description: null
                },
                sexList: [{ Id: "f", label: "Konieta" }, { Id: "m", label: "Mężczyzna" }],
                showAddLocationDialog: false,
                funeralLocation: {},
                qrCodeBase64: null,
                wordShow: false,
                paymentCreateDisplay: false,
                payment: { currency: 'PLN' },
                filteredOptions: [],
            };
        },
        mounted: function () {        
            toolService.Get('Obituary/GetCemetery')
                .then(data => {
                    localStorage.setItem("Cemetery", JSON.stringify(data));                    
                })
                .catch(error => {
                    console.error('Błąd:', error);
                });
            toolService.Get('Obituary/GetFuneralCeremonyLocation')
                .then(data => {
                    localStorage.setItem("FuneralCeremonyLocation", JSON.stringify(data));
                })
                .catch(error => {
                    console.error('Błąd:', error);
                });
            toolService.Get('Obituary/GetProducts?text=x')
                .then(data => {
                    localStorage.setItem("Products", JSON.stringify(data));
                })
                .catch(error => {
                    console.error('Błąd:', error);
                });
            this.fetchQRCode();
        },
        computed: {
            getCurrency() {
                const products = localStorage.getItem("Products");
                let data = JSON.parse(products);
                const uniqueCurrencies = [...new Set(data.map(item => item.currency))];
                return uniqueCurrencies;
            },
            getPaymentUrl() {
                const payment = localStorage.getItem("PaymentUrl");
                console.log(this.refresh);
                if (payment != undefined && payment != null && payment.length > 0) {
                    return JSON.parse(payment);
                }
                return undefined;                
            },
            getProducts() {
                const products = localStorage.getItem("Products");
                let data = JSON.parse(products);
                return data;
            },
            getUser() {
                const user = sessionStorage.getItem("User");
                return JSON.parse(user);
            },
            getCustomer() {
                const customer = localStorage.getItem("OpenObituaryCustomer");
                console.log(this.refresh);
                if (customer != undefined && customer != null && customer.length > 0) {
                    return JSON.parse(customer).customer;
                }
                return {};
            },
            getObituary() {
                const obituary = localStorage.getItem("OpenObituaryeObituary");
                console.log(this.refresh);
                if (obituary != undefined && obituary != null && obituary.length > 0) {   
                    let r = JSON.parse(obituary);
                    if (r.obituary !== undefined)
                        return r.obituary
                    else
                        return {};
                }
                return {};
            },
            getCemetery() {
                const cemetery = localStorage.getItem("Cemetery");   
                console.log(this.refresh);
                if (cemetery != undefined && cemetery != null && cemetery.length > 0) {
                    return JSON.parse(cemetery).map(item => ({
                        cemeteryId: item.cemeteryId,
                        label: `${item.name}, ${item.address0} ${item.city}`
                    }));
                } else {
                    // Handle the case when the cemetery parameter is invalid or empty
                    return [];
                }                
            },
            getFuneralLocation() {
                console.log(this.refresh);
                const funeralLocation = localStorage.getItem("FuneralCeremonyLocation");
                if (funeralLocation != undefined && funeralLocation != null && funeralLocation.length > 0) {
                    return JSON.parse(funeralLocation).map(item => ({
                        locationId: item.funeralCeremonyLocationId,
                        label: `${item.name}, ${item.address} ${item.city}`
                    }));
                } else {
                    // Handle the case when the cemetery parameter is invalid or empty
                    return [];
                }
            }
        },
        watch: {
            'eObituary.cemeteryId': function (query) {
                console.log(query);
                if (query.length > 3) {
                    this.filteredOptions = this.getCemetery.filter(option =>
                        option.label.toLowerCase().includes(query)
                    );
                }                
            }
        },
        methods: {
            paymentCheck() {
                let id = this.getPaymentUrl.paymentId;
                toolService.Get('Obituary/CheckPayment?paymentId=' + id).then(data => {
                    console.log(data);     
                    const payment = localStorage.getItem("PaymentUrl");                    
                    if (payment != undefined && payment != null && payment.length > 0) {
                        let j = JSON.parse(payment);
                        j['transactionStatus'] = data.transactionStatus;
                        j['transactionStatusName'] = data.transactionStatusName;
                        localStorage.setItem("PaymentUrl", JSON.stringify(j));
                        this.refresh++;
                    }
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
                    });
            },
            onCurrencyChange() {
                console.log(this.payment.currency);
            },
            PaymentSave() {                
                let product = this.getProducts.filter(item => item.currency == this.payment.currency && item.productId == this.payment.product)[0];

                let data = {
                    customerId: this.getCustomer.customerId,
                    pageId: this.getObituary.epadoPageId,
                    productId: this.payment.product,
                    productName: product.name,
                    price: product.price,
                    amount: 1,
                    currency: this.payment.currency,
                    obituaryId: this.getObituary.obituaryId
                }
                console.log(data);
                let t = this;
                toolService.Post('Obituary/CreatePaymentUrl', data)
                    .then(data => {
                        if (data.paymentId > 0) {
                            localStorage.setItem("PaymentUrl", JSON.stringify(data));
                            this.refresh++;
                            t.paymentCreateDisplay = false;
                        }                        
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                    });               
            },
            async fetchQRCode() {
                if (this.getObituary.epadoCode == undefined) {
                    return;
                }

                try {
                    const response = await fetch(
                        `../api/Obituary/GenerateQRCode?text=https://epado.com/${this.getObituary.epadoCode}`,
                        {
                            method: 'GET',
                            headers: {
                                Authorization: `Bearer ${this.getUser.token}`
                            }
                        }
                    );

                    // Konwersja odpowiedzi na blob
                    const blob = await response.blob();

                    // Konwersja blob na Base64
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.qrCodeBase64 = reader.result; // Ustaw Base64 jako źródło obrazka
                    };
                    reader.readAsDataURL(blob);
                } catch (error) {
                    console.error(error.message);                    
                }            
            },
            clear() {
                localStorage.removeItem("OpenObituaryCustomer");
                localStorage.removeItem("OpenObituaryeObituary");     
                localStorage.removeItem("PaymentUrl");
                this.refresh++;
            },
            NewEObituary() {
                const date = new Date();
                date.setHours(12, 0, 0, 0);
                this.eObituary = {
                    customerId: this.getCustomer.customerId, 
                    firstName: '',
                    lastName: '',
                    funeralDate: null,
                    funeralDateInternal: null,
                    funeralTime: date,
                    birthDate: null,
                    deathDate: null,
                    cemeteryId: null,
                    alley: null,
                    plot: null,
                    sex: '',
                    description: null,
                    funeralCeremonyLocationId:null
                }
                this.eObituaryDisplay = true;
            },
            LocationSave() {
                let t = this;
                toolService.Post('Obituary/CreateFuneralCeremonyLocation', this.funeralLocation)
                    .then(data => {
                        localStorage.setItem("FuneralCeremonyLocation", JSON.stringify(data));
                        this.refresh++;
                        t.showAddLocationDialog = false;                       
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                    });
            },
            eObituarySave() {
                console.log(JSON.stringify(this.eObituary))
                let t = this;

                const funeralDate = new Date(this.eObituary.funeralDateInternal);
                const funeralTime = new Date(this.eObituary.funeralTime);

                const combinedDateTime = new Date(
                    funeralDate.getFullYear(),
                    funeralDate.getMonth(),
                    funeralDate.getDate(),
                    funeralTime.getHours(),
                    funeralTime.getMinutes(),
                    funeralTime.getSeconds(),
                    funeralTime.getMilliseconds()
                );

                // Konwersja do czasu lokalnego w formacie ISO
                const localISODateTime = new Date(
                    combinedDateTime.getTime() - combinedDateTime.getTimezoneOffset() * 60000
                ).toISOString();

                this.eObituary.funeralDate = localISODateTime;
                toolService.Post('Obituary/CreateeObituary', this.eObituary)
                    .then(data => {
                        localStorage.setItem("OpenObituaryeObituary", JSON.stringify(data));
                        this.refresh++;
                        this.fetchQRCode();
                        if (data.obituary.obituaryId > 0) {
                            t.eObituaryDisplay = false;
                        }
                    })
                    .catch(error => {
                        console.error('Błąd:', error);
                    });
            },
            createNewCustomer() {
                this.customer = {
                    name: '',
                    surname: '',
                    phone: '',
                    email: '',
                    termsAccepted: true,
                    marketingConsent: true,
                    eReceiptConsent: true
                };
                this.newCustomerDisplay = true;

                
            },
            CustomerSave() {
                console.log(JSON.stringify(this.customer))
                let t = this;
                toolService.Post('Obituary/CreateCustomer', this.customer)
                .then(data => {                    
                    localStorage.setItem("OpenObituaryCustomer", JSON.stringify(data));
                    this.refresh++;
                    if (data.customer.customerId > 0) {
                        t.newCustomerDisplay = false;
                    }
                })
                .catch(error => {
                    console.error('Błąd:', error);                
                });

                /*
                toolService.Get('Configuration/GetUsers').then(data => {
    console.log(data);
    t.users = data.data;
    t.userNodes = t.buildUserTree(data.data);
})
    .catch(error => {
        console.error('Błąd:', error);
        //this.messages.push({ id: 2, content: 'Błąd zapisywania' });
    });
                */
            }
        }
    };
</script>

<style scoped>
    .full-width > * {
        width: 100%;
    }

    .boxsizingBorder {
        border: 1px solid #999999;
        width: 100%;
        margin: 5px 0;
        padding: 3px;
    }
</style>