import { authHeader } from '../helpers';

export function getGetRequestOptions() {
    return {
        method: 'GET',
        headers: authHeader(),
    };
}

export function getPostRequestOptions(data) {
    return {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
}