<template>
    <div class="grid" style="margin-top: 30px;">
        <div class="col-4"></div>
        <Panel class="col-4">
            <h2>Logowanie</h2>
            <div class="grid">
                <div v-if="mfaSMS == false" class="col-12">
                    <div style="">
                        <label for="username">Login</label>
                        <InputText id="username" v-model="username" placeholder="Wprowadź login" />
                    </div>
                </div>
                <div class="col-12">
                    <div style="">
                        <label v-if="mfaSMS == false" for="password">Hasło</label>
                        <label v-if="mfaSMS == true" for="password">Kod autoryzacyjny</label>
                        <Password id="password" v-model="password" :feedback="false" toggleMask :placeholder="getPlaceHolder" @keydown.enter="onSubmit" />
                    </div>
                </div>
                <div class="col-12">
                    <div style="display: flex; align-items: center;">
                        <Checkbox inputId="rememberMe" v-model="rememberMe" :binary="true" />
                        <label for="rememberMe" style="margin-left: 8px;">Zapamiętaj mnie na tym komputerze</label>
                    </div>
                </div>
                <div class="col-12">
                    <div v-if="mfaSMS == false" class="full-width">
                        <Message v-for="msg of messages" :key="msg.id" severity="error" :life="3000">{{ msg.content }}</Message>
                        <Button label="Zaloguj się" class="p-button-success" @click="onSubmit" />
                    </div>
                </div>
            </div>
        </Panel>
    </div>
    

            <div v-if="1==0" class="grid">
                <div class="col-2 full-width">
                </div>
                <div v-if="1==0" class="col-4 full-width" style="margin-top:50px">
                    <Panel>
                        <div class="col-12 full-width">
                            <h2>Rejestracja</h2>
                            <div class="col-12 full-width">
                                <label for="username">Adres email</label>
                                <InputText v-model="email"
                                           type="email"
                                           placeholder="example@example.com"
                                           @blur="validateEmail"
                                           :class="{'p-invalid': emailError}" />
                                <small v-if="emailError" class="p-error">{{ emailError }}</small>
                            </div>
                            <Button label="Rejestracja" class="p-button-success" @click="onSubmitRegster" />
                        </div>
                    </Panel>
                </div>
                <div class="col-1 full-width">
                </div>
                <div class="col-4 full-width" style="margin-top:50px">
                    <Panel>
                        <h2>Logowanie</h2>
                        <div v-if="mfaSMS == false" class="p-field">
                            <label for="username">Login</label>
                            <InputText id="username" v-model="username" placeholder="Wprowadź login" />
                        </div>

                        <div class="p-field full-width">
                            <label v-if="mfaSMS == false" for="password">Hasło</label>
                            <label v-if="mfaSMS == true" for="password">Kod autoryzacyjny</label>
                            <Password id="password" v-model="password" :feedback="false" toggleMask :placeholder="getPlaceHolder" @keydown.enter="onSubmit" />
                        </div>

                        <div v-if="mfaSMS == false" class="p-field-checkbox">
                            <Checkbox inputId="rememberMe" v-model="rememberMe" :binary="true" />
                            <label for="rememberMe">Zapamiętaj mnie na tym komputerze</label>
                        </div>

                        <div v-if="rememberMe && 1==0" class="p-field">
                            <label for="password">Nazwa komputera</label>
                            <InputText id="computerName" v-model="computerName" feedback="false" toggleMask placeholder="Wprowadź nazwę komputera" />
                        </div>

                        <Message v-for="msg of messages" :key="msg.id" severity="error" :life="3000">{{ msg.content }}</Message>
                        <Button label="Zaloguj się" class="p-button-success" @click="onSubmit" />

                    </Panel>
                </div>
                <div class="col-1 full-width">
                </div>
            </div>
            <Dialog position="top" v-model:visible="messageDisplay" :maximizable="true" :modal="true" :style="{width: '90vw'}" :showHeader="true" header="Dane klienta">
                <div class="grid">
                    <div class="col-12 full-width">
                        <h2>{{messageContent}}</h2>
                    </div>
                    <div class="col-12 full-width">
                        <Button label="Zamknij" icon="pi pi-times" @click="messageDisplay=false;" class="p-button-outlined p-button-secondary" />
                    </div>
                </div>
            </Dialog>
</template>

<script>
    import axios from 'axios';
    import Password from 'primevue/password';

    export default {
        components: { Password },
        data() {
            return {
                username: '',
                password: '',
                rememberMe: false,
                computerName: "",
                mfaSMS: false,
                messages: [],
                email: '',
                emailError: '',
                messageDisplay: false,
                messageContent:''
            };
        },
        computed: {
            getPlaceHolder() {
                return (this.mfaSMS) ? 'Wprowadź kod' : 'Wprowadź hasło';
            }
        },
        mounted: function () {
            let deviceKey = localStorage.getItem("DeviceKey");
            if (deviceKey != undefined) {
                this.rememberMe = deviceKey != 'undefined';
            }
        },
        methods: {
            validateEmail() {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.email) {
                    this.emailError = 'Adres e-mail nie może być pusty.';
                } else if (!emailPattern.test(this.email)) {
                    this.emailError = 'Podany adres e-mail jest niepoprawny.';
                } else {
                    this.emailError = '';
                }
            },
            async onSubmitRegster() {                
                const response = await axios.post('../api/Register/RegisterEmail', {
                    email: this.email
                });
                console.log(response);
                if (response.status == 200) {
                    this.messageContent = response.data.message;
                    this.messageDisplay = true;
                }
            },
            async onSubmit() {
                try {
                    let deviceKey = localStorage.getItem("DeviceKey");
                    if (deviceKey == undefined) {
                        deviceKey = ''
                    }
                    if (!this.mfaSMS) {
                        const response = await axios.post('../api/users/authenticate', {
                            username: this.username,
                            password: this.password,
                            rememberMe: this.rememberMe,
                            computerName: this.computerName,
                            deviceKey: deviceKey
                        });
                        if (response.data.twoFactorAuthentication == "SMS") {
                            this.password = "";
                            this.mfaSMS = true;
                        }
                        else if (response.data.token.length > 20)
                        {
                            localStorage.setItem("DeviceKey", response.data.twoFactorAuthentication);
                            sessionStorage.setItem("User", JSON.stringify(response.data));
                            this.$emit('update-value', 543);
                            console.log('Zalogowano pomyślnie:', response.data);
                        }
                    }
                    else {
                        const response = await axios.post('../api/users/TwoFactorAuthenticationConfirm', {
                            username: this.username,
                            password: this.password,
                            rememberMe: this.rememberMe,
                            computerName: this.computerName,
                            deviceKey: deviceKey
                        });
                        sessionStorage.setItem("User", JSON.stringify(response.data));
                        if (response.data.twoFactorAuthentication == "SMS") {
                            this.mfaSMS = true;
                        }
                        else {
                            if (response.data.twoFactorAuthentication != undefined) {
                                localStorage.setItem("DeviceKey", response.data.twoFactorAuthentication);
                            }
                            this.$emit('update-value', 543);
                            console.log('Zalogowano pomyślnie:', response.data);
                        }
                    }       
                    
                } catch (error) {
                    this.messages.push({ id: 2, content: (this.mfaSMS) ? 'Nieprawidłowy kod' : 'Nieprawidłowy login lub hasło' });
                    console.error('Błąd logowania:', error);
                }
            }
        }
    };
</script>

<style scoped>
    .login-container {
        padding-top: 40px;
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    h2 {
        text-align: center;
    }

    .p-field {
        margin-bottom: 16px;
    }

    .p-field-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
    .full-width > * {
        width: 100%;
    }
</style>